import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import findIndex from "lodash/findIndex";

import * as ProgramsApi from "../api/programsApi";
import {
  composeSetValues,
  findInitial,
  getNextDayIdx,
  isEqualExercise,
  isSubsequentExercise,
} from "../helpers/training";
import { removeObjectsDuplicatesFromArray } from "../helpers/sorters";
import { EError } from "../constants/enums";

export const addProgram = createAsyncThunk(
  "programs/addProgram",
  ProgramsApi.addProgram
);

export const addProgramFromPersonalProgramTemplate = createAsyncThunk(
  "programs/addProgram",
  ProgramsApi.addProgramFromPersonalProgramTemplate
);

export const updateProgram = createAsyncThunk(
  "programs/updateProgram",
  ProgramsApi.updateProgram
);

export const loadTraining = createAsyncThunk("programs/loadTraining", (args) =>
  ProgramsApi.loadTrainingWithCache(args)
);

export const getProgram = createAsyncThunk(
  "programs/getProgram",
  ({ awaitPromise, ...args }, { dispatch }) => {
    return ProgramsApi.getProgramWithCache(args).then(async (res) => {
      await dispatch(loadProgramIds({ id: args.id }));
      await Promise.resolve(awaitPromise);
      return res;
    });
  }
);

export const loadProgramIds = createAsyncThunk(
  "programs/loadProgramIds",
  (args) => ProgramsApi.loadProgramIdsWithCache(args)
);

export const changeExercise = createAsyncThunk(
  "programs/changeExercise",
  ProgramsApi.changeExercise
);

export const updateSet = createAsyncThunk(
  "programs/updateSet",
  ProgramsApi.updateSet
);

export const updatePb = createAsyncThunk(
  "programs/updatePb",
  ProgramsApi.updatePb
);

export const saveDayNotes = createAsyncThunk(
  "programs/saveDayNotes",
  ProgramsApi.saveDayNotes
);

export const loadSetHistory = createAsyncThunk(
  "programs/loadSetHistory",
  (args) => ProgramsApi.loadSetHistoryWithCache(args)
);

export const loadPreviousSetHistoryById = createAsyncThunk(
  "programs/loadPreviousSetHistoryById",
  (args) => ProgramsApi.loadPreviousSetHistoryByIdWithCache(args)
);

export const loadOtherSetHistoryById = createAsyncThunk(
  "programs/loadOtherSetHistoryById",
  async (args, { getState }) => {
    const previousSets = getState().programs.loadSetHistoryData.history;
    const previousSetIds = Object.values(previousSets)
      .map(({ id }) => id)
      .filter(Boolean);
    const response = await ProgramsApi.loadOtherSetsWithCache(args);
    const filteredSets = response?.history.other.filter(
      ({ id }) => !previousSetIds.includes(id)
    );
    return filteredSets;
  }
);

const setIsFirstLastSet = (state) => {
  const { currentProgram, currentExercise, currentSetIdx } = state;
  if (!currentExercise) return false;
  const firstExercise = currentProgram.exercises.find(
    (exercise) =>
      exercise.workout_index !== null && exercise.exercise_id !== null
  );
  const nextExercise = currentProgram.exercises.find(
    (exercise) =>
      isSubsequentExercise(exercise, currentExercise) &&
      exercise.workout_index !== null &&
      exercise.exercise_id !== null
  );
  state.isFirstSet =
    isEqualExercise(currentExercise, firstExercise) && currentSetIdx === 0;
  state.isLastSet =
    !nextExercise && currentSetIdx === currentExercise.sets.length - 1;
};

const setCurrentProgram = (state, isCurrent) => {
  const programsCount = state.numberOfPrograms;

  if (programsCount === 0) {
    state.currentProgram = null;
    return;
  }
  if (!state.currentProgram?.exercises?.length) return;

  const setFirstSetOfDay = (dayIdx) => {
    const {
      currentProgram: { exercises },
    } = state;
    if (exercises.every((exercise) => exercise.workout_index === null)) {
      return;
    }
    let exercise = null;
    let searchIndex = dayIdx;
    let exercisesArray = exercises;

    const lastDayIndex = exercises[exercises?.length - 1]?.day_index;
    while (!exercise && searchIndex <= lastDayIndex) {
      const index = findIndex(
        exercisesArray,
        (exercise) => exercise.day_index === searchIndex
      );
      exercisesArray = exercisesArray.slice(index);
      exercise = exercisesArray.find(
        (exercise) =>
          isEqualExercise(exercise, {
            day_index: searchIndex,
            workout_index: 0,
            exercise_index: 0,
          }) && exercise.exercise_id
      );
      if (!exercise) searchIndex++;
    }

    if (!exercise) return;

    const firstSet = exercise.sets[0];

    const pb = state.pb.find((el) => {
      return el.exercise === exercise.exercise_id;
    });

    const composedSet = composeSetValues(
      exercise,
      0,
      firstSet,
      pb?.pb_value,
      pb?.is_calculate,
      state.currentProgram?.personal_program_template
    );
    programsSlice.caseReducers.setCurrentSet(state, {
      payload: { composedSet, exercise },
    });
  };

  const initial = findInitial(state.currentProgram.exercises);
  if (!initial && isCurrent === null) {
    state.currentDayIdx = 0;
    return true;
  };

  if (!initial || isCurrent !== null) {
    setFirstSetOfDay(0);
    return;
  };
  const { exercise, set, isToday, isRecent } = initial;

  if (!exercise) {
    state.currentSet = null;
    return;
  }

  const set_index = exercise.sets.indexOf(set);

  state.currentExercise = exercise;
  state.currentSetIdx = set_index;
  state.currentDayIdx = exercise.day_index;

  const { exercise_id } = state.currentExercise;
  const pb = state?.pb?.find(({ exercise }) => exercise === exercise_id);

  state.currentSet = composeSetValues(
    exercise,
    set_index,
    set,
    pb?.pb_value,
    pb?.is_calculate,
    state.currentProgram?.personal_program_template
  );

  setIsFirstLastSet(state);

  if (state.isLastSet) {
    setFirstSetOfDay(0);
    return;
  }
  if (isRecent) {
    if (isToday) {
      programsSlice.caseReducers.setNextSet(state);
    } else {
      const nextDayIdx = getNextDayIdx(
        state.currentProgram.exercises,
        exercise.day_index
      );
      setFirstSetOfDay(nextDayIdx || 0);
    }
  }
};

const programsSlice = createSlice({
  name: "programs",
  initialState: {
    loading: true,
    programIds: [],
    loadingIds: true,
    currentProgram: null,
    currentProgramIdx: null,
    currentProgramIdxForPaging: 1,
    currentDayIdx: null,
    loadingProgram: true,
    currentExercise: null,
    currentSet: null,
    currentSetIdx: null,
    isFirstSet: null,
    isLastSet: null,
    selectedExercise: null,
    changeAllExercise: true,
    pb: null,
    isSavingPb: false,
    isStrengthTest: false,
    history: { sets: {}, history: {} },
    historyDetails: null,
    setHistory: null,
    loadingSetHistory: true,
    isSavingSet: false,
    error: "",
    preselectedWeight: null,
    preselectedRep: null,
    numberOfPrograms: 0,
    addProgramSuccess: false,
    loadSetHistoryLoading: false,
    loadSetHistoryError: null,
    loadSetHistorySuccess: false,
    loadSetHistoryData: null,
    isOtherHistoryExist: false,
    otherSets: [],
    isOtherSetsLoading: false,
    loadingOtherSetsError: null,
    loadingOtherSetsSuccess: false,
    currentProgramData: null,
    programDaysCount: null,
    totalReps: null,
  },
  reducers: {
    setTotalReps(state, action) {
      state.totalReps = action.payload;
    },
    setProgramDaysCount(state, action) {
      state.programDaysCount = action.payload;
    },
    clearError(state) {
      state.error = "";
    },
    setCurrentSet(state, action) {
      if (action.payload.reset) {
        state.currentSet = null;
        state.currentExercise = null;
        state.currentSetIdx = null;
        state.currentDayIdx = null;
        state.loadSetHistoryData = null;
        return
      };
      if (state.isSavingSet) return;
      const { composedSet, exercise } = action.payload;
      state.currentSet = composedSet;
      state.currentExercise = exercise;
      state.currentSetIdx = composedSet.set.number - 1;
      state.currentDayIdx = composedSet.day - 1;

      setIsFirstLastSet(state);
    },
    setSelectedExercise(state, action) {
      state.selectedExercise = action.payload;
    },
    setChangeAllExercise(state, action) {
      state.changeAllExercise = action.payload;
    },
    setNextSet(state) {
      const {
        currentProgram,
        currentExercise,
        currentSetIdx,
        isSavingSet,
        isLastSet,
      } = state;
      if (isSavingSet) return;
      if (isLastSet) return;

      let newCurrentSetIdx =
        currentSetIdx === currentExercise.sets.length - 1
          ? 0
          : currentSetIdx + 1;
      let newCurrentExercise;
      let newCurrentSet;
      if (newCurrentSetIdx === 0) {
        newCurrentExercise = currentProgram.exercises.find(
          (exercise) =>
            isSubsequentExercise(exercise, currentExercise) &&
            exercise.workout_index !== null
        );
        newCurrentSet = newCurrentExercise.sets[0];
      } else {
        newCurrentExercise = currentExercise;
        newCurrentSetIdx = currentSetIdx + 1;
        newCurrentSet = currentExercise.sets[newCurrentSetIdx];
      }

      if (!newCurrentSet) {
        newCurrentExercise = currentProgram.exercises.find((exercise) => {
          return exercise.day_index > newCurrentExercise.day_index && exercise.exercise_id !== null;
        });
        newCurrentSet = newCurrentExercise.sets[0];
      }

      const { exercise_id } = newCurrentExercise;
      const currentPb = state.pb?.find((record) => {
        return record.exercise === exercise_id;
      });

      state.currentSet = composeSetValues(
        newCurrentExercise,
        newCurrentSetIdx,
        newCurrentSet,
        currentPb?.pb_value,
        currentPb?.is_calculate,
        currentProgram?.personal_program_template
      );

      state.currentExercise = newCurrentExercise;
      state.currentSetIdx = newCurrentSetIdx;

      setIsFirstLastSet(state);
    },
    setPrevSet(state) {
      const { currentProgram, currentExercise, currentSetIdx, isSavingSet } =
        state;

      if (isSavingSet) return;

      const allSets = currentProgram?.exercises?.reduce(
        (acc, exercise) => [
          ...acc,
          ...exercise.sets.map((set) => ({
            ...set,
            day_index: exercise.day_index,
            workout_index: exercise.workout_index,
            exercise_index: exercise.exercise_index,
            exercise,
            set,
          })),
        ],
        []
      );

      const currentSetIndex = allSets.findIndex(
        (item) =>
          item.day_index === currentExercise.day_index &&
          item.workout_index === currentExercise.workout_index &&
          item.exercise_index === currentExercise.exercise_index &&
          item.set_index === currentSetIdx
      );

      const prevSetIndex = currentSetIndex - 1;

      const prevSet = allSets[prevSetIndex];

      const newCurrentExercise = prevSet.exercise;
      const newCurrentSetIdx = prevSet.set_index;
      const newCurrentSet = prevSet.set;

      const { exercise_id } = newCurrentExercise;

      const currentPb = state.pb.find((record) => {
        return record.exercise === exercise_id;
      });

      state.currentSet = composeSetValues(
        newCurrentExercise,
        newCurrentSetIdx,
        newCurrentSet,
        currentPb?.pb_value,
        currentPb?.is_calculate,
        currentProgram?.personal_program_template,
      );

      state.currentExercise = newCurrentExercise;
      state.currentSetIdx = newCurrentSetIdx;

      setIsFirstLastSet(state);
    },
    setHistoryDetails(state, action) {
      state.historyDetails = action.payload;
    },
    setPreselectedWeight(state, action) {
      state.preselectedWeight = action.payload;
    },
    setPreselectedRep(state, action) {
      state.preselectedRep = action.payload;
    },
    clearPreselectedValues(state) {
      state.preselectedWeight = null;
      state.preselectedRep = null;
    },
    clearHistory(state) {
      state.history = { sets: {}, history: {} };
    },
    setCurrentProgramIdx(state, action) {
      state.currentProgramIdx = action.payload;
    },
    setCurrentProgramIdxForPaging(state, action) {
      state.currentProgramIdxForPaging = action.payload;
    },
    resetAddProgramSuccess(state) {
      state.addProgramSuccess = false;
    },
    setOtherSets(state, action) {
      state.otherSets = action.payload;
    },
  },
  extraReducers: {
    [getProgram.pending]: (state) => {
      state.loadingProgram = true;
      state.currentDayIdx = null;
    },
    [getProgram.fulfilled]: (state, action) => {
      const { id, programIndex } = action.meta.arg;
      state.currentProgramData = { id, programIndex };
      state.loadingProgram = false;
      state.numberOfPrograms = action.payload?.count;
      state.error = "";

      state.currentProgram = action.payload?.results[0];

      const isTrainingUntouched = setCurrentProgram(state, action.payload?.previous);
      const exercises = state.currentProgram?.exercises;

      if (!exercises) return;

      const allNotCompletedSets = [];

      exercises.forEach((ex) => {
        const lastSet = ex.sets.find((set) => !set.is_completed);
        if (lastSet) {
          allNotCompletedSets.push({ lastSet, exercise: ex });
        }
      });

      if (
        !allNotCompletedSets[0]?.lastSet &&
        !allNotCompletedSets[0]?.exercise
      ) {
        const lastExercise = exercises?.findLast((ex) => ex.sets.length);
        const exercise = lastExercise || exercises[exercises.length - 1];
        const set = exercise?.sets.find((set) => !set.is_completed);
        const lastSet = set || exercise?.sets[exercise.sets.length - 1];
        allNotCompletedSets.push({
          lastSet,
          exercise: lastExercise,
        });
      };
      if (isTrainingUntouched) {
        state.currentSet = allNotCompletedSets[0]?.lastSet;
        state.currentExercise = allNotCompletedSets[0]?.exercise;

        const exercise_id = state.currentExercise?.exercise_id;
        const currentPb = state.pb.find(
          (record) => record.exercise === exercise_id
        );

        state.currentSetIdx = state.currentSet?.set_index;
        state.currentSet = composeSetValues(
          state.currentExercise,
          state.currentSetIdx,
          state.currentSet,
          currentPb?.pb_value,
          currentPb?.is_calculate,
          action.payload?.results[0]?.personal_program_template
        );

        setIsFirstLastSet(state);
      };
    },
    [getProgram.rejected]: (state, action) => {
      state.loadingProgram = false;
      state.error = action.error.message || EError.default_error;
    },
    [loadTraining.pending]: (state) => {
      state.loading = true;
    },
    [loadTraining.fulfilled]: (state, action) => {
      state.pb = action.payload?.pb;
      state.isStrengthTest = action.payload?.strength?.length !== 0;
      state.loading = false;
      state.error = "";
      setCurrentProgram(state);
    },
    [loadTraining.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message || EError.default_error;
    },
    [loadProgramIds.pending]: (state) => {
      state.loadingIds = true;
    },
    [loadProgramIds.fulfilled]: (state, action) => {
      state.loadingIds = false;
      state.error = "";
      state.programIds = action.payload;
    },
    [loadProgramIds.rejected]: (state, action) => {
      state.loadingIds = false;
      state.error = action.error.message || EError.default_error;
    },
    [loadSetHistory.pending]: (state) => {
      state.loadingSetHistory = true;
    },
    [loadSetHistory.fulfilled]: (state, action) => {
      state.loadingSetHistory = false;
      state.error = "";
      state.setHistory = action.payload;
    },
    [loadSetHistory.rejected]: (state, action) => {
      state.loadingSetHistory = false;
      state.error = action.error.message || EError.default_error;
    },
    [addProgram.pending]: (state) => {
      state.loadingProgram = true;
      state.addProgramSuccess = false;
    },
    [addProgram.fulfilled]: (state, action) => {
      state.loadingProgram = false;
      state.currentDayIdx = 0;
      state.currentProgram = action.payload?.new_program;

      if (!action.payload?.previous_program_is_replaced) {
        state.numberOfPrograms++;
        state.programIds.push({
          personal_program_template: action.payload.new_program.id,
        });
      }
      state.currentProgramIdx = state.numberOfPrograms;
      state.pb = action.payload?.pb;
      setCurrentProgram(state);
      state.addProgramSuccess = true;

      const exercises = state.currentProgram?.exercises;
      if (!exercises) return;

      const allNotCompletedSets = [];

      exercises.forEach((ex) => {
        const lastSet = ex.sets.find((set) => !set.is_completed);
        if (lastSet) {
          allNotCompletedSets.push({ lastSet, exercise: ex });
        }
      });

      state.currentSet = allNotCompletedSets[0]?.lastSet;
      state.currentExercise = allNotCompletedSets[0]?.exercise;

      const exercise_id = state.currentExercise?.exercise_id;
        const currentPb = state.pb.find(
          (record) => record.exercise === exercise_id
        );

      state.currentSetIdx = state.currentSet?.set_index;
      state.currentSet = composeSetValues(
        state.currentExercise,
        state.currentSetIdx,
        state.currentSet,
        currentPb?.pb_value,
        currentPb?.is_calculate,
        action.payload?.new_program,
        true,
      );

      setIsFirstLastSet(state);
    },
    [addProgram.rejected]: (state, action) => {
      state.loadingProgram = false;
      state.error = action.error.message || EError.default_error;
      state.addProgramSuccess = false;
    },
    [changeExercise.pending]: (state) => {
      state.changingExercise = true;
    },
    [changeExercise.fulfilled]: (state, action) => {
      state.changingExercise = false;
      state.pb = action.payload?.pb;
      state.currentProgram = action.payload?.program;
      setCurrentProgram(state);
    },
    [changeExercise.rejected]: (state, action) => {
      state.changingExercise = false;
      state.error = action.error.message || EError.default_error;
    },
    [updateSet.pending]: (state) => {
      state.isSavingSet = true;
    },
    [updateSet.fulfilled]: (state, action) => {
      const newCurrentProgram = action.payload?.program;
      state.isSavingSet = false;
      state.currentProgram = newCurrentProgram;
      state.pb = action.payload?.pb;
      if (!newCurrentProgram) return;
    },
    [updateSet.rejected]: (state, action) => {
      state.isSavingSet = false;
      state.error = action.error.message || EError.default_error;
    },
    [updatePb.pending]: (state) => {
      state.isSavingPb = true;
    },
    [updatePb.fulfilled]: (state, action) => {
      const { pb } = action.payload;
      state.pb = pb;
      state.isSavingPb = false;
      const exerciseData = state.currentProgram.exercises[state.currentSetIdx];
      const currentSet = exerciseData?.sets[state.currentSetIdx];
      state.currentSet = composeSetValues(
        exerciseData,
        state.currentSetIdx,
        currentSet,
        pb?.pb_value,
        pb?.is_calculate,
        state.currentProgram?.personal_program_template
      );
    },
    [updatePb.rejected]: (state, action) => {
      state.isSavingPb = false;
      state.error = action.error.message || EError.default_error;
    },
    [saveDayNotes.fulfilled]: (state, action) => {
      const program_index = action.payload.program_index;
      const note = action.payload;
      const programNotes = state.currentProgram.notes;
      const toDelete = !action.payload?.text;
      const noteIndexPre = programNotes.findIndex(
        ({ day_index }) => day_index === note.day_index
      );
      const noteIndex =
        noteIndexPre !== -1 ? noteIndexPre : programNotes.length;

      if (toDelete) {
        programNotes.splice(noteIndex, 1);
        if (state.currentProgramIdx === program_index)
          state.currentProgram.notes.splice(noteIndex, 1);
      } else {
        programNotes[noteIndex] = note;
        if (state.currentProgramIdx === program_index)
          state.currentProgram.notes[noteIndex] = note;
      }
    },
    [updateProgram.pending]: (state) => {
      state.loading = true;
    },
    [updateProgram.fulfilled]: (state, action) => {
      state.currentProgram = action.payload;
      state.error = "";
    },
    [updateProgram.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message || EError.default_error;
    },
    [loadPreviousSetHistoryById.pending]: (state) => {
      state.loadSetHistoryLoading = true;
      state.loadSetHistorySuccess = false;
    },
    [loadPreviousSetHistoryById.fulfilled]: (state, action) => {
      if (!action.payload) return;
      state.loadSetHistoryData = action.payload;
      state.isOtherHistoryExist = action.payload?.history?.show_more_button;
      state.otherSets = action.payload?.history?.other;
      state.loadSetHistoryError = null;
      state.loadSetHistorySuccess = true;
      state.loadSetHistoryLoading = false;
    },
    [loadPreviousSetHistoryById.rejected]: (state, action) => {
      state.loadSetHistoryLoading = false;
      state.loadSetHistorySuccess = false;
      state.loadSetHistoryError = action.error.message;
    },
    [loadOtherSetHistoryById.pending]: (state) => {
      state.isOtherSetsLoading = true;
      state.loadingOtherSetsSuccess = false;
    },
    [loadOtherSetHistoryById.fulfilled]: (state, action) => {
      const { best, previous_by_rank, previous_by_index } =
      state.loadSetHistoryData.history;
      state.loadingOtherSetsError = null;
      state.loadingOtherSetsSuccess = true;
      state.isOtherSetsLoading = false;
      const filteredSets = action.payload.filter((set) => {
        return (
          set.id !== best.id &&
          set.id !== previous_by_index.id &&
          set.id !== previous_by_rank.id
        );
      });

      state.otherSets = removeObjectsDuplicatesFromArray([...filteredSets, ...current(state.otherSets)]);
    },
    [loadOtherSetHistoryById.rejected]: (state, action) => {
      state.isOtherSetsLoading = false;
      state.loadingOtherSetsSuccess = false;
      state.loadingOtherSetsError = action.error.message;
    },
  },
});

export const {
  clearError,
  setCurrentSet,
  setSelectedExercise,
  setChangeAllExercise,
  setNextSet,
  setPrevSet,
  setHistoryDetails,
  setPreselectedWeight,
  setPreselectedRep,
  clearPreselectedValues,
  setCurrentProgramIdx,
  clearHistory,
  setCurrentProgramIdxForPaging,
  resetAddProgramSuccess,
  setOtherSets,
  setProgramDaysCount,
  setTotalReps,
} = programsSlice.actions;
export default programsSlice.reducer;
